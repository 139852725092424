import './index.css';

function PasswordForm(props) {
  return (
    <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
    <div class="flex items-center justify-between">
    	    <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
        	Password
      	    </label>
      	    <input class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="xxxxxxx" />
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline" type="button">
        Try For It
      </button>
     </div>
     </form>
  )
}

function EmailForm(props) {
return (
 <form action="#" class="bg-white rounded px-8 pt-6 pb-8 mb-4">
          <div class="mb-6">
	      <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
		An email you check
	      </label>
      	      <input type="email" id="email" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" placeholder="An email you check" required />
          </div>
          <div class="sm:col-span-2">
	      <label class="block text-gray-700 text-sm font-bold mb-2" for="message">
		Your message
	      </label>
              <textarea id="message" rows="6" class="block p-2.5 w-full text-sm text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" placeholder="Leave a comment..."></textarea>
          </div>
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline" type="button">
        Ask For It
      </button>
 
      </form>
)
}

function App() {
  return (
    <div class="w-full max-w-xs">
	<PasswordForm />
	<EmailForm />
    </div>
  );
}

export default App;
